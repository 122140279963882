import React from "react"
import Layout from "../components/layout/Layout"
import LabelText from "../components/LabelText"
import Button from "../components/Button"
import heroImage from "../images/merger_slogan.jpg"
import felixSchirlImg from "../images/felix-schirl.jpg"
import felixSchroederImg from "../images/felix_schroeder.jpg"
import Testimonial from "../components/Testimonial"

const TrboChatchampPage = () => {
  return (
    <Layout>
      <section className="pt-16 md:pt-16">
        <div className="container text-center mx-auto px-8">
          <div className="front-page">
            <img src={heroImage} />
          </div>
          <h1 className="text-3xl lg:text-4xl xl:text-5xl leading-none font-semibold">
            Chatchamp & trbo gehen gemeinsame Wege
          </h1>
          <p className="text-l lg:text-m lg:pr-12 font-light">
            Der Experte für Onsite-Personalisierung trbo und der Guided Selling
            Anbieter Chatchamp schließen sich zusammen. Nach einer langjährigen
            Partnerschaft gehen die Unternehmen nun den nächsten Schritt und
            trbo übernimmt Chatchamp. Alle wichtigen Informationen findet ihr
            hier.
          </p>
          <p className="text-l lg:text-m mt-12 font-light">
            <Button
              size="xl"
              link="https://www.trbo.com/presse/personalisierungs-plattform-trbo-akquiriert-guided-selling-anbieter-chatchamp"
            >
              Zur Pressemitteilung
            </Button>
          </p>
          <div>
            <div className="lg:my-20 my-8">
              <div className="m-8 container mx-auto text-center">
                <LabelText className="text-gray-700">
                  Alles rund um trbo & Chatchamp ❤️
                </LabelText>
              </div>
              <div className="lg:m-8">
                <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 list-none m-0 p-0">
                  <li className="col-span-1 bg-white rounded-lg shadow">
                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                      <div className="flex-1">
                        <h2 className="text-xl font-semibold">
                          🙋‍♀️ Wer ist trbo?
                        </h2>
                        <p className="text-sm font-light leading-relaxed">
                          trbo ist führender Technologieanbieter für die
                          dynamische Onsite-Personalisierung, Optimierung und
                          Testing. Mit der KI-basierten Plattform von trbo
                          lassen sich Inhalte und Angebote von Webseiten
                          individuell und inspirierend gestalten – in Echtzeit
                          abgestimmt auf die Bedürfnislage von Kunden in ihrer
                          Customer Journey. Hierzu analysiert ein
                          selbstlernender Algorithmus das User-Verhalten auf
                          Basis von über 50 Besucher-Merkmalen. Diese Daten
                          erlauben anschließend eine zielgerichtete Auslieferung
                          verschiedenster Inhalte im Design des
                          Online-Auftritts, die das Einkaufserlebnis von
                          Website-Besuchern nachweislich optimieren. Namhafte
                          Händler und Hersteller wie Ströer, Telefónica, mydays,
                          Triumph, Vertbaudet und XXXLutz vertrauen auf die
                          SaaS-Lösung von trbo.
                        </p>
                        <div className="mt-6">
                          <Button size="m" link="https://www.trbo.com">
                            Mehr über trbo erfahren
                          </Button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="col-span-1 bg-white rounded-lg shadow">
                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                      <div className="flex-1">
                        <h2 className="text-xl font-semibold">
                          🤝 Warum trbo?
                        </h2>
                        <p className="text-sm font-light leading-relaxed">
                          “Für uns war die Entscheidung, zukünftig noch enger
                          mit trbo zusammen zu arbeiten eine leichte. Wir haben
                          bereits in den vergangenen Jahren die weitreichende
                          Expertise von trbo als Personalisierungs-Anbieter und
                          A/B-Testing Tool kennengelernt und durch einige
                          gemeinsame Kunden auch erfolgreiche Use-Cases
                          erarbeitet. Da ein Datenaustausch und die weitere
                          Nutzung dieser Daten immer wichtiger wird, haben wir
                          schon in der Vergangenheit auf Schnittstellen gesetzt.
                          Mit trbo als Personalisierungs-Software mit einer
                          Vielzahl bestehender Datenschnittstellen, haben wir
                          hier den perfekten Fit, um die Ergebnisse unserer
                          Produktberatung auch mit weiteren Datenquellen zu
                          verheiraten und so für eine optimale ganzheitliche
                          User-Experience zu sorgen”, so Felix Schröder, CEO von
                          Chatchamp
                        </p>
                        <div className="mt-6">
                          <Button size="m" link="https://www.trbo.com">
                            Mehr über trbo erfahren
                          </Button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="col-span-1 bg-white rounded-lg shadow">
                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                      <div className="flex-1">
                        <h2 className="text-xl font-semibold">
                          🛫 Die nächsten Schritte
                        </h2>
                        <p className="text-sm font-light leading-relaxed">
                          Zukünftig werden Chatchamp und trbo ihre strategische
                          Produktentwicklung gemeinsam ausrichten, um noch enger
                          zusammenzuwachsen und ihren Kunden noch mehr
                          Möglichkeiten einer perfekten Onsite User Experience
                          zu bieten. Die beiden Lösungen werden auch weiterhin
                          als Stand-Alone Produkte einsetzbar sein, um allen
                          Onlineshops den bestmöglichen Product Fit zu
                          ermöglichen. Die ersten Synergien können von
                          Bestandskunden schon jetzt genutzt werden. Von
                          direkter Integration, ausführlichen Reportings,
                          A/B-Test bis hin zum Datenaustausch. Die ersten
                          Schritte für eine ganzheitliche User Experience sind
                          bereits getan. In einer gemeinsamen Demo zeigen wir
                          dir gerne wie du am besten von beiden Tools
                          profitieren kannst.
                        </p>
                        <div className="mt-6">
                          <Button
                            size="m"
                            link="https://calendly.com/chatchamp/product-demo"
                          >
                            Produktdemo buchen
                          </Button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Testimonial
            avatar={felixSchroederImg}
            name="Felix Schröder"
            role="CEO von Chatchamp"
            testimonial="Für uns war die Entscheidung, zukünftig noch enger mit trbo zusammen zu arbeiten eine leichte. Wir haben bereits in den vergangenen Jahren die weitreichende Expertise von trbo als Personalisierungs-Anbieter und A/B-Testing Tool kennengelernt und durch einige gemeinsame Kunden auch erfolgreiche Use-Cases erarbeitet. Mit trbo als Personalisierungs-Software mit einer Vielzahl bestehender Datenschnittstellen, haben wir hier den perfekten Fit, um die Ergebnisse unserer Produktberatung auch mit weiteren Datenquellen zu verheiraten und so für eine optimale ganzheitliche User-Experience zu sorgen."
          />
          <Testimonial
            avatar={felixSchirlImg}
            name="Felix Schirl"
            role="CEO von trbo"
            testimonial="Mit Chatchamp haben wir einen langjährigen Partner an unserer Seite, der unser Produktportfolio optimal ergänzt. Uns hat sich aufgrund der hohen Nachfrage eines Guided Selling Produkts die Frage gestellt, ob wir eine Eigenentwicklung angehen oder zukünftig noch enger mit Chatchamp zusammenarbeiten. Nicht zuletzt aufgrund des optimalen persönlichen Fits haben wir uns nun zu diesem Schritt entschieden."
          />
          <section className="container mx-auto my-20 py-8 lg:py-24 bg-gray-200 rounded-lg text-center">
            <h3 className="text-2xl lg:text-5xl font-semibold px-8">
              In nur wenigen Schritten zum digitalen Produktberater!
            </h3>
            <p className="mt-8 text-m lg:text-xl font-light pr-16 pl-16">
              Teste Chatchamp und erstelle in wenigen Minuten deinen ersten
              digitalen Produktberater. Kundenzentrierte Beratung zu
              digitalisieren war noch nie so einfach.
            </p>
            <p className="mt-8">
              <Button size="xl" link="http://contact.chatchamp.com/testzugang">
                Jetzt testen
              </Button>
            </p>
          </section>
        </div>
      </section>
    </Layout>
  )
}

export default TrboChatchampPage
